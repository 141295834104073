
  .menu-bars {
    font-size: 2rem;
    background: none;
    color: #00cded;
  }

  .nav{
    
    z-index: 11;

    &-menu{
      background-color: #00cded;
      width: 250px;
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 0;
      left: -100%;
      transition: 850ms;

    }
    &-menu.active{
      left: 0;
      transition: 350ms;
    }

    &-overlay{
      background-color: #dbdbdb71;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      transition: 850ms;
      display: none;
    }
    &-overlay.active{
      opacity: 1;
      display: block;
      transition: 350ms;
    }

    span {
      margin-left: 16px;
    }

  }
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    list-style: none;
    height: 60px;
    font-weight: bold;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #00cded;
  }
  
  .nav-menu-items {
    width: 100%;
    text-align: initial;
    padding-left: .5rem;
  }
  
  .navbar-toggle {
    background-color: #00cded;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  