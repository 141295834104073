.targeta-task.targeta-task.targeta-task{
    width: 350px;
    border-style: solid;
    border-color: #00cbeb;
    border-width: 5px;
    border-radius: 10px;
    font-size: 0.8rem;
    height: fit-content;
    margin-top: 80px;
}

.fila-1{
    width: 100%;
    display: inline-block;
}

.id-tarjeta{
    display: inline-block;
    margin:1rem;
    float: left;
    font-size: x-large;
}
.fial3-2.fial3-2.fial3-2{
    width: 50%;
    display: inline-block;
    float:left;
}
.stage-tarjeta{
    display: inline-block;
    margin:1rem;
    float: right;
}

.fila-descripcion{
    text-align: left;
    width: 100%;
    display: inline-block;
    
}

.descipcion-title{
    margin:1rem;
    font-weight: 700;
    font-size:0.9rem;
}

.descripcion-content{
    display: inline-block;
    margin: 0 1rem;
    
}

.codigopostal{
    display: inline-block;
    margin:1rem;
    font-weight: 700;
    float: left;
    width: auto;
    text-align: left;
}

.fila3{
    width: 100%;
    display: block;

}

.ver-fotos-link{
    margin:1rem;
    width: 50%;
    float: left;
    text-align: left;
}

.btn-presupuesto.btn-presupuesto.btn-presupuesto{
    display: flex;
    margin: 1rem;
    float: right;
    margin-top: 1.5em;
    width: 40%;
}

.fila4{

    width: 100%;
    display: inline-block;
    
}

.div-servicio.div-servicio.div-servicio{
    display: inline-block;
    margin:1rem;
    float: left;
    text-align: left;
    width: 50%;
}


.btn-calendario.btn-calendario.btn-calendario{
    display: inline-block;
    margin:1rem;
    float: right;
    width: 30%;
    margin-top: 0.75em;
}

.btn-subir-documentos{
}

.calendaricon{
    color: #00cbeb;
    width: 100%;
    font-size: xxx-large;
}

.div-fact{
    display: inline-block;
    margin:1rem;
    float: left;
    text-align: left;
    width: 50%;
}

.documenticon-si{
    color: #00cbeb;
    font-size: 4rem;
    margin-left: .2rem;
}
.documenticon-no{
    color: grey;
    font-size: 4rem;
    margin-left: .2rem;
}
.button-documentos{
    background-color: #00cbeb;
    color: white;
    border-color: #00cbeb;
    border-radius: 4px;
    width: 60%;
    height: auto;
    line-height: normal !important;
    margin: .5rem auto;
    grid-column: span 2;
}

.title{
    font-weight: 700;
    font-size: 0.9rem;
    padding-bottom: .2rem;
}


.main-container{
    position: relative;
}

.ficha-container{
    margin: .8rem .4rem 0 .4rem;
    border-style: solid;
    padding: 1rem;
    border-color: #00cbeb;
    border-width: 5px;
    border-radius: 2rem;
    text-align: initial;
    display: grid;
    grid-template-rows: min-content;
    gap: 3rem;
    a, a:visited{
        color:#00cbeb;
    }
}

.ficha-task{
    display:block;
    text-align: center;
}

.ficha-servicio{
    display: grid;
    gap: .2rem;
}

.ficha-servicio-footer{
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-auto-rows: min-content;
    gap: 2rem;
}

.ficha-btn-presupuesto{
    display: flex;
    align-items: flex-end;
    grid-column: 2 /-1;
    grid-row: 1 / span 2;
    text-align:right;
    a, div{
        display: flex;
        align-items: flex-end;
    }
}

.ficha-title{
    font-weight: 700;
    font-size: 1.2rem;
    color: #4c4c4c;
    padding-bottom: .4rem;

}

.ficha-header-title{
    font-weight: 700;
    font-size: 2rem;
    color: #4c4c4c;
    padding-bottom: .4rem;


}

.ficha-bold{
    font-weight: 500;
}

.ficha-realizacion{
    display: grid;
    gap: .2rem;
    grid-template-columns: 1fr min-content;
}

.ficha-realizacion-calendario{
    grid-column-start: 2;
    grid-row: 1 / span 3;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.ficha-button-documentos{
    margin: .5rem auto !important;
    grid-column: span 2;
    width: auto;
    max-width: 25rem;
}

.call-client-button-container {
    margin: .5rem auto !important;
    grid-column: span 2;
    width: 87% !important;
    max-width: 16.2rem;
    height: auto !important;
    line-height: normal !important;
    margin: .5rem auto;
}

.call-client-button {
    color: white !important;
    text-decoration: none;
    width: 100%; 
}

.ficha-facturacion-contenedor{
    display: grid;
    gap: 1rem;

}

.ficha-facturacion-contenedor-bloque{
    display: grid;
    gap: .2rem;
}

.ficha-taskstage{
    text-align: right;
}
.ficha-factura-button{
    display: flex;
    align-items: flex-end;
}

  .link-noimages{
    color: grey;
    text-decoration: none;
  }

  .custom-button1{
      min-width: 2rem !important;
      width: 100%;
      margin:auto;
  }

  .custom-button3{
    border-color: #dc3545;
    background-color: #dc3545;
    color:white;
  }

  .rejectTaskButton{
      background-color: #dc3545;
      border-color: #dc3545;
  }