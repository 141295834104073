.customGoBack{
    color: #00cded;
    top: 3rem;
    left: 2rem;
}

.main-title{
    text-align: center;
    padding-right: 16%;
    font-weight: bold;
    color: #00cded;
}

.title-row{
    margin-top: 2rem;
}

.subtitle-row{
    margin: 1rem;
}

.container-form-inputs{
    padding: 1rem;
    width: 100%;
}

.container-form-footer{
    padding: 1rem;
    width: 100%;
}

.concept-input-text-row{
    padding-left: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.input-number-row{
    text-align:center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.send-budget-button{
    border: 1px solid #00cded;
    background: #00cded;
    border-radius: 6px;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 600;
    height: 3rem;
    line-height: 0px;
    margin: 6px;
    padding: 14px 18px;
    text-align: center;
    width: 25rem;
}


// Removing arrows from input field

/* Chrome, Safari, Edge, Opera */
.input-number-row::-webkit-outer-spin-button,
.input-number-row::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input-number-row {
    -moz-appearance: textfield;
}