.header {
  border-bottom: 1px solid #00cded;
  height: 64px;
  overflow: hidden;
  width: 100%;
  display: flex;

  .logo {
    float: left;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 190px;
  }
}
