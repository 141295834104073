.img-container {
	text-align: center;
}
.logo-loading {
	display: flex;
	height: 180px;
	width: 100%;
}
.box {
	align-self: flex-end;
	animation-duration: 1.7s;
	animation-iteration-count: infinite;
	height: 70px;
	margin: 0 auto 0 auto;
	transform-origin: bottom;
	width: 100px;
	margin-bottom: 3rem;
}
.bounce-7 {
	animation-name: bounce-7;
	animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}
@keyframes bounce-7 {
	0% {
		transform: scale(1, 1) translateY(0);
	}
	10% {
		transform: scale(1.1, 0.9) translateY(0);
	}
	30% {
		transform: scale(0.9, 1.1) translateY(-50px);
	}
	50% {
		transform: scale(1.05, 0.95) translateY(0);
	}
	57% {
		transform: scale(1, 1) translateY(-7px);
	}
	64% {
		transform: scale(1, 1) translateY(0);
	}
	100% {
		transform: scale(1, 1) translateY(0);
	}
}
.text {
	font-weight: 500;
	font-family: Raleway, arial, sans-serif;
	font-size: 20px;
}