.profile .title-404 {
    color: #00cded;
    font-size: 40px;
    margin-top: 0;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }
  

  .task-list-title {
    margin: auto;
    // margin-top: 40px;
    margin-top: 1rem;
    width: 10rem;
    display: flex;
  
    h3 {
      font-family: 'Raleway', sans-serif;
      // font-size: 26px;
      font-size: 1.2rem;
      font-weight: 600;
      margin: auto auto 2px auto;
    }
  }