.footer {
  //background: rgba(0, 205, 237, 0.15);
  background: #fff;
  height: 64px;
  margin-top: 20px;
  overflow: hidden;
  width: 100%;

  .logo-footer {
    float: right;
    height: 33px;
    padding-top: 14px;
    padding-right: 66px;
    width: 202px;
  }
}

/* For Desktops more or less */
@media only screen and (min-device-width : 400px) and (min-width: 400px) {
  .footer {
    height: auto;
  }
}
