.custom-button {
  border: 1px solid #00cded;
  background: #00cded;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 600;
  height: 32px;
  line-height: 0px;
  margin: 6px;
  padding: 14px 18px;
  text-align: center;
  width: 266px;

  &:focus {
    outline: none;
  }
}

.grey-button {
  border: 1px solid #c4c4c4;
  background: #c4c4c4;
}

.green-button {
  border: 1px solid #4fd04c;
  background: #4fd04c;
}
