.slide-container{
    padding-top: 4rem;
    position: relative;
}

.gallery{
    width: 100vw;
    display: flex;
    flex-direction: column;
    &__container{
        position: relative;
        width: 100%;
        margin-bottom: .4rem;
        .download-image{
            position: absolute;
            top: 4px;
            right: 4px;
        }
    }
    &__image{
        width: 100%;
        
    }
}


