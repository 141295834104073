.upload-after-photos {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: 80px 1fr;
  width: 370px;

  .step-back      { grid-area: 1 / 1 / 2 / 2; }
  .step-title     { grid-area: 1 / 2 / 2 / 3; }
  .file-uploader  { grid-area: 2 / 1 / 3 / 3; }

  .file-uploader {
    margin-left: 10px;
    margin-right: 10px;
  }

  .custom-plus-div {
    display: flex;
  }

  .custom-plus-icon {
    background: url('../../assets/images/plus-solid.svg');
    background-size: 18px 18px;
    display: block;
    margin-top: 3px;
    width: 18px;
    height: 18px;
  }
}

.step-back {
  color: #00cded;
  margin-top: 32px;
  width: 100px;
}

.step-title {
  margin-left: -100px;
  margin-top: 30px;
}

/* Put media queries here */
@media only screen and (min-device-width : 400px) and (min-width: 400px) {
  .upload-after-photos {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Small screens media query live here */
@media only screen
and (max-device-width : 400px)
and (max-width: 400px)
and (orientation : portrait) {
  .upload-after-photos {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen
and (max-device-width : 360px)
and (max-width: 360px)
and (orientation : portrait) {
  .upload-after-photos {
    width: 360px;
  }
}

@media only screen
and (max-device-width : 320px)
and (max-width: 320px)
and (orientation : portrait) {
  .upload-after-photos {
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
  }
}

@media only screen
and (max-device-width : 480px)
and (max-width: 480px)
and (orientation : landscape) {
  .upload-after-photos {
    width: 480px;
  }
}
