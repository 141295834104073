@mixin ellipsis-multiline($font-size, $line-height, $lines-to-show, $margin:"0") {
  $height-calc: $font-size*$line-height*$lines-to-show;
  display: block;
  display: -webkit-box;
  font-size: $font-size*1px;
  line-height: $line-height;
  max-height: ($height-calc + $margin)*1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;

  p:not(:first-of-type) {
    display: none;
  }
}

.bg-mh {
    background-color: #fff;
    margin-top: 20px;
  
    .paginating-button {
      background-color: #fff;
      border: 2px solid #00cded;
      border-radius: 4px;
      font-family: Raleway, sans-serif;
      font-size: 20px;
      font-weight: 500;
      margin: 2px;
      padding: 4px 8px 6px 8px;
  
      &:focus {
        outline: none;
      }
    }
  }
  
  .task-filter {
    color: #00cded;
    font-family: Raleway, sans-serif;
    font-weight: 500;
    top: -24px;
    right: -125px;
    position: relative;
  }
  
  .filter-section {
    //border: 3px dashed #00cded;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    padding: 12px 2px;
    width: 88%;
  
    .filter-by-id {
      display: table;
      font-family: Raleway, sans-serif;
      font-size: 18px;
      margin: 8px 4px;
  
      label {
        display: table-cell;
        text-align: left;
        width: 164px;
      }
  
      input {
        border: 1px solid #ccc;
        border-radius: 6px;
        display: table-cell;
        font-size: 18px;
        font-weight: 500;
        padding-left: 6px;
        text-align: left;
        height: 32px;
        width: 150px;
  
        &:focus {
          outline: none;
        }
      }
    }
  
    .filter-by-category {
      display: table;
      font-family: Raleway, sans-serif;
      font-size: 18px;
      margin: 8px 4px;
  
      label {
  
        display: table-cell;
        text-align: left;
        width: 164px;
      }
  
      .category-select-container {
        display: table-cell;
        font-size: 16px;
        width: 156px;
  
        &:focus {
          outline: none;
        }
  
        .category-select__control {
          border-radius: 6px;
  
          .category-select__value-container {
  
          }
          .category-select__indicators {
  
            .category-select__indicator-separator {
              margin-bottom: 0;
              margin-top: 0;
            }
          }
        }
  
      }
    }
  
    .filter-by-date {
      display: table;
      font-family: Raleway, sans-serif;
      font-size: 18px;
      margin: 8px 4px;
  
      label {
        display: table-cell;
        text-align: left;
        width: 164px;
      }
  
      .date-select-container {
        display: table-cell;
        width: 156px;
  
        &:focus {
          outline: none;
        }
  
        .date-select__control {
          border-radius: 6px;
  
          .date-select__value-container {
  
          }
          .date-select__indicators {
  
            .date-select__indicator-separator {
              margin-bottom: 0;
              margin-top: 0;
            }
          }
        }
  
      }
    }
  
    .reset-filters {
      color: #00cded;
      font-family: Raleway, sans-serif;
      font-weight: 500;
      padding-left: 2px;
      text-align: left;
    }  
  }

  .task-list-wrapp{
    padding: 0 .4rem;
  }

  .task-list-container{
    display: grid;
    grid-template-rows: min-content 5rem;
    border: solid #00cded;
    border-radius: .3rem;
    max-width: 30rem;
    margin: auto;
    margin-bottom: 1rem;

  }

  .task-list-container-visit{
    display: grid;
    grid-template-rows: min-content 5rem;
    border: solid #ffbd59;
    border-radius: .3rem;
    max-width: 30rem;
    margin: auto;
    margin-bottom: 1rem;

  }

  .task-list-header{

  display: flex;
  justify-content: space-between;
  padding: .5rem;
  color: #FFF;
  background-color: #00cded;

  }

  .task-list-header-visit{

    display: flex;
    justify-content: space-between;
    padding: .5rem;
    color: #FFF;
    background-color: #ffbd59;
  
    }

  .task-list-description{
    margin: .5rem;
    text-align: start;
    @include ellipsis-multiline(16,1.4,3,0);
  }

  .helper-task-list-description{
    margin: .5rem;
    text-align: start;
    padding-right: 1rem;
    @include ellipsis-multiline(16,1.4,3,0);

  }
  .task-list-content{
    position: relative;
  }


  .task-list-description-star{
    position: absolute;
    right: 1px;
    top: 1px;
    color: #fff176;
    font-size: 1.5rem;
  }