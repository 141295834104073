.task-title {
  margin: 24px auto 0px;

  h3 {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
