
.main-container{
    position: relative;
}

.ficha-container{
    margin: .8rem .4rem 0 .4rem;
    border-style: solid;
    padding: 1rem;
    border-color: #00cbeb;
    border-width: 5px;
    border-radius: 10px;
    text-align: initial;
    display: grid;
    grid-template-rows: min-content;
    gap: 3rem;
    padding-top: 3rem;
    a, a:visited{
        color:#00cbeb;
    }
}

.button-sugerir-visitas{
    background-color: white;
    color: #00cbeb;
    border-width: 2px;
}

.ficha-task{
    display: flex;
    justify-content: space-between;
    
}

.ficha-visita{
    display: grid;
    gap: .2rem;
}


.ficha-title{
    font-weight: 700;
    font-size: 1.2rem;
    color: #4c4c4c;
    padding-bottom: .4rem;

}

.ficha-header-title{
    font-weight: 700;
    font-size: 2rem;
    color: #4c4c4c;
    padding-bottom: .4rem;


}
.ficha-images{
    margin: 1em 0;
}
.ficha-botones{
    text-align: center;
}

.ficha-bold{
    font-weight: 500;
}

.accept-button{
    margin: 1rem;
    margin-top: -1rem;
}


  .link-noimages{
    color: grey;
    text-decoration: none;
  }

