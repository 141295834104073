.skeleton-body {
  grid-area: 2 / 1 / 3 / 2;
  margin-top: 80px;
}

.skeleton-title {
  margin-bottom: 10px;
}

.skeleton-paragraph {
  margin: 6px;
}

@media only screen and (min-device-width : 512px) and (min-width: 512px) {
  .skeleton-paragraph {
    margin-left: 75px;
    margin-right: 75px;
  }
}

@media only screen and (min-device-width : 660px) and (min-width: 660px) {
  .skeleton-paragraph {
    margin-left: 200px;
    margin-right: 200px;
  }
}

@media only screen and (min-device-width : 910px) and (min-width: 910px) {
  .skeleton-paragraph {
    margin-left: 300px;
    margin-right: 300px;
  }
}

@media only screen and (min-device-width : 1110px) and (min-width: 1110px) {
  .skeleton-paragraph {
    margin-left: 400px;
    margin-right: 400px;
  }
}

@media only screen and (min-device-width : 1310px) and (min-width: 1310px) {
  .skeleton-paragraph {
    margin-left: 500px;
    margin-right: 500px;
  }
}

@media only screen and (min-device-width : 1510px) and (min-width: 1510px) {
  .skeleton-paragraph {
    margin-left: 600px;
    margin-right: 600px;
  }
}

@media only screen and (min-device-width : 1710px) and (min-width: 1710px) {
  .skeleton-paragraph {
    margin-left: 800px;
    margin-right: 800px;
  }
}
