.rbc-btn-group{
    margin-top: 10px;
}

.rbc-time-view{
    .rbc-event.rbc-event.rbc-event{        
        justify-content: center;
        align-items: center;
        padding: 2px;
        }
    }

.week-event{
    font-size: calc(0.35rem + 1vw);
    padding-bottom: 0.35rem;
}
.rbc-event.rbc-event.rbc-event{
    //background-color: rgb(0, 205, 237);
    z-index: 10;
    
}

.rbc-event.rbc-event.rbc-event.new-event {
    background-color: green;
    color: white;
}
.rbc-time-view .rbc-event.rbc-event.rbc-event{
    height: 2% !important;
}

.event-day-box{
    height: 100%;
}

.event-day-box-task{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rbc-day-slot .rbc-event-content.rbc-event-content.rbc-event-content.rbc-event-content  {
    width: 100%;
    flex: 1;
    word-wrap: break-word;
    line-height:normal; 
    height: auto;
    /* margin: 0; */
    /* padding: 0; */
    min-height: auto; 
}

.rbc-day-slot .rbc-event-label{
    display: none;
}

.rbc-time-view span {
     margin-left: 0!important; 
}

.rbc-time-view .rbc-row.rbc-row.rbc-row {
    
     min-height: auto;
     
}
.rbc-header.rbc-header.rbc-header{
    white-space: normal;
}
.rbc-time-view.rbc-time-view.rbc-time-view{
    min-height: calc(65vh - 10px);
}

.rbc-toolbar.rbc-toolbar.rbc-toolbar{
    flex-flow: column wrap;
}
.rbc-toolbar .rbc-toolbar-label.rbc-toolbar-label.rbc-toolbar-label{
    margin-top: 10px;
}

.rbc-calendar.rbc-calendar.rbc-calendar{
    height: 70vh;
}

.rbc-row-segment.rbc-row-segment.rbc-row-segment.rbc-row-segment {
    font-size: calc(0.6rem + 1vw)!important;
}


.span-id{
    font-weight: 700;
    margin-left: 0!important;

    & > a{
        color: #00cded;
    }
}

.modal-init{
    border: 1px solid rgb(0, 205, 237);
    border-radius: .2rem;
    padding: 1rem;
    background-color: white;
    margin: auto;
    max-width: 24rem;
    display: grid;
    gap: 2rem;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.ReactModal__Overlay--after-open{
    display: flex;
    z-index: 10;
    padding: 0 .4rem;
}


.modal-button-documentos{
    background-color: #00cbeb;
    color: white;
    border-color: #00cbeb;
    border-radius: 4px;
    margin: auto;
}

.bold{
    font-weight: 700;
    margin: 0;
}

.new-event{
    display: flex;
    height: 100%;
}

.icono-plus{
    margin: auto;
}
.modal-propouse-text{
    margin-top: -1rem;
}
.modal-propouse-dates{
    display: grid;
    gap: .2rem;
    padding-left: 2rem;

    div{
        text-transform: capitalize;
    }
}

.icon-back{
    color: #00cbeb;
}

.rbc-time-slot.calendar-slot-lock {
    background-color: #f5f5f5;
}

// .goBack {
//     color: #00cded;
//     position: absolute;
//     top: 5.8rem;
//     left: 4rem;
//   }

.rbc-time-slot.rbc-time-slot{
    z-index: 10;
    border-top: none;
}
.rbc-day-slot .rbc-events-container.rbc-events-container {
    margin-right: 1.5rem;

    .rbc-event{
        max-width: 70%;
    }
}