.task-hr{
  width: 90%;
  border: 1px solid #00cded;
}

.task-link-title {
  margin-top: 40px;

  h3 {
    font-family: 'Raleway', sans-serif;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 2px;
  }
}

.task-list-title {
  // margin-top: 40px;
  margin-top: 1rem;
  width: 12rem;

  h3 {
    font-family: 'Raleway', sans-serif;
    // font-size: 26px;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2px;
  }
}

.task-list-row {
  border: 2px solid #00cded;
  border-radius: 6px;
  cursor: pointer;
  //border-spacing: 10px;
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  //table-layout: fixed;
  width: 88%;

  .task-list-col-1 {
    background: #00cded;
    color: #fff;
    display: table-cell;
    font-weight: 700;
    padding: 8px 4px;
    width: 22%;
  }

  .task-list-col-2 {
    display: table-cell;
    padding: 8px 4px;
  }

  .task-list-col-3 {
    display: table-cell;
    padding: 8px 4px;
    width: 28%;
  }

  .task-title-container{
    display: flex;
    padding: 0 3rem;
  }

  .task-title{
    margin: 0 auto;
  }
}

.task-list-row-visit {
  border: 2px solid #00cded;
  border-radius: 6px;
  cursor: pointer;
  //border-spacing: 10px;
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  //table-layout: fixed;
  width: 88%;

  .task-list-col-1 {
    background: #00cded;
    color: #fff;
    display: table-cell;
    font-weight: 700;
    padding: 8px 4px;
    width: 22%;
  }

  .task-list-col-2 {
    display: table-cell;
    padding: 8px 4px;
  }

  .task-list-col-3 {
    display: table-cell;
    padding: 8px 4px;
    width: 28%;
  }

  .task-title-container{
    display: flex;
    padding: 0 3rem;
  }

  .task-title{
    margin: 0 auto;
  }
}

